import './App.css';

function App() {
  console.log(`⠀⠀⠀⠀⠀⠀⠀⠀⣠⣴⠞⡟⣙⠶⢤⡀⠀⢀⣀⣠⣤⡤⣤⢤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⢀⡾⢋⠔⡒⠌⡔⢡⠃⡝⣻⣭⠑⣡⠒⡰⡐⢪⠩⢿⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⠀⣼⡿⣘⣄⣛⣼⣤⣧⣼⣤⣀⣿⣜⣠⣧⣧⣼⣤⣧⠿⣿⢤⡀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣀⣀⣀⣀⡀⠀⠀⠀⣀⡼⡟⢻⠻⡤⣀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⠀⠀⣠⠿⠶⣼⣽⣍⣦⣴⣄⣆⣞⣼⣭⠿⣦⣔⣢⣦⣶⣶⣷⣷⣿⣻⣆⠀⠀⠀⠀⢀⣶⠛⡍⢩⡘⠤⡉⢜⣋⡵⡞⠥⡑⢌⡡⠚⠤⡉⢷⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⠀⢤⣾⠏⡘⠬⡿⣟⢦⣀⣾⣯⣿⡿⣷⠀⠀⠸⠙⠚⣺⣻⣿⣄⠀⠀⠀⠈⡇⠀⣀⣴⢾⢳⡚⠖⡷⠾⡶⠷⣬⣮⣥⠾⠶⠷⢲⠶⡭⣖⡥⢸⣻⠄⠀⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⢰⣿⠒⡌⣱⠘⡔⣺⣿⣿⣏⣓⠻⠷⠿⣤⣴⡧⣄⣾⣿⣿⣥⡟⠀⣀⣠⡶⠃⡴⠛⠛⠛⠯⠽⣷⣶⣷⣾⣶⠛⠛⢒⣾⣥⡿⣖⠓⠒⣻⣷⠷⣘⠳⣄⠀⠀⠀⠀⠀⠀⠀
  ⠀⠀⢠⣟⡱⢰⠡⣚⡐⢻⣷⢿⣛⡿⢷⣾⣶⣯⣵⣴⣦⣿⣿⣿⣿⣿⣿⣿⣿⡗⠘⣆⠀⠀⠀⢰⡿⣯⣿⣦⠀⠸⡀⠀⢿⣛⣿⣾⣿⢧⣾⣿⣷⡟⢡⠃⢎⣷⠀⠀⠀⠀⠀⠀
  ⠀⠀⠘⣷⣌⡃⢧⡐⢡⢊⢎⡙⠻⠿⢯⣽⣿⣽⣿⣶⣯⠿⠿⡛⣛⡿⠛⠉⠀⠀⠀⠙⣷⣦⣤⣬⣿⣿⣿⣿⠔⡪⠟⡿⣾⣭⣭⣷⣶⣿⡿⣷⠟⡡⢃⠞⡠⢾⡇⠀⠀⠀⠀⠀
  ⠀⠀⢠⣾⣇⢜⢢⢨⣡⣊⡔⢌⡑⠚⠤⣁⣆⡦⣐⠆⡜⣠⢃⡴⠋⠀⠀⠀⠀⠀⠀⠀⠛⠛⠿⢿⣿⣿⣟⣯⣿⢿⢿⠿⡿⣭⣯⣯⣷⡯⢞⡋⢎⡱⣉⠆⣑⠂⣇⠀⠀⠀⠀⠀
  ⠀⢀⣿⣿⣿⣿⣶⣥⢃⠬⡙⠫⢍⠛⡍⣡⠊⡔⣡⣚⣴⣴⠋⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠳⣴⡈⡍⣙⢫⢙⡛⢙⠩⢍⡉⢆⠣⠰⢌⢃⠲⢤⠩⢄⣽⣿⣆⠀⠀⠀⠀
  ⠀⣸⣿⣿⣞⣿⣻⣿⣿⣶⣷⣧⣮⣾⣶⣷⣾⣿⣿⢿⣿⣻⣧⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠓⢼⣠⠃⡜⢛⠛⡳⠖⡞⡖⡛⠷⣚⠛⣋⣤⣷⣾⣿⣟⣿⡆⠀⠀⠀
  ⠀⣿⣿⢯⣿⢿⣻⣯⣿⢿⣟⣿⣿⢿⣿⣻⣿⣻⣽⣾⣟⣿⣿⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⣿⣶⣷⣬⣦⣃⣔⣡⣃⢧⣐⣍⣶⣿⣿⣿⡿⣿⣽⣿⣷⠀⠀⠀
  ⢠⣿⣿⢿⣻⣿⢿⣽⣾⣿⣻⣽⣾⢿⣯⣿⢷⣿⣯⣷⣿⣻⣾⣿⡀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢰⣿⣟⣿⣻⣿⢿⣿⣿⡿⣿⣿⡿⣿⣯⣿⡾⣟⣿⣷⣯⣿⡇⠀⠀
  ⢸⣿⣻⣿⡿⣽⣿⣯⣿⢾⡿⣽⣾⣿⣻⣾⡿⣷⣿⣳⣿⢯⣷⣿⣷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣾⣿⣻⣽⣿⢾⣿⣻⣾⢿⣟⣾⡿⣟⣷⣿⣻⣿⣟⣾⡷⣿⣿⠀⠀
  ⢸⣿⢿⣳⣿⢿⣷⣟⣯⣿⢿⣻⣷⢿⣯⣷⣿⢿⣾⢿⣽⣿⣻⣾⢿⣇⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢸⣿⣟⣿⣽⣾⡿⣯⣿⣽⣿⣻⣽⣿⣟⣿⡾⣟⣷⣿⢯⣿⡷⣿⡆⠀
  ⠸⣿⡿⣟⣿⣻⣽⣾⢿⣯⣿⢿⣽⡿⣯⣷⡿⣿⢯⣿⣟⣾⡿⣽⣿⣻⡄⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⣾⣿⢯⣿⣽⣾⢿⣿⣽⣾⢿⣽⡿⣷⡿⣯⣿⢿⣻⣾⡿⣯⣿⡿⣧⠀
  ⠈⢿⣿⣿⣻⣽⡿⣾⣿⣻⣾⡿⣯⣿⢿⣽⣿⣻⣿⢯⣿⣯⣿⣟⣷⣿⣷⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢠⣿⡿⣟⣯⣿⣾⢿⣻⣾⣟⣿⢯⣿⡷⣿⡿⣽⣿⢿⣯⣿⣟⣷⣿⣿⠀
  ⠀⠈⢻⣿⣿⣷⣿⣿⡿⠿⠾⠿⠿⡽⡿⡿⠾⣿⡽⠿⣷⠛⡳⣛⢛⠻⡟⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠘⡿⣿⣟⣯⣷⣿⣻⣿⣳⡿⣯⣿⢷⣿⡿⣽⣿⣽⣿⣟⣾⣿⣷⣿⠿⠂
  ⠀⠀⢸⠟⢿⣾⣷⣿⣷⢉⡜⣡⠓⡴⠑⣼⡟⣩⢑⣾⢛⠛⡝⡛⢖⠻⡧⣄⣀⣴⠞⡶⢦⣀⣤⡤⢤⡤⡿⠴⡬⢭⠿⠿⢯⡍⡻⠿⣝⠛⡏⢿⣿⣿⣽⣾⣿⡩⣉⠵⢨⣸⠀⠀
  ⠀⠀⠘⣿⢠⠋⡍⣬⠑⢪⢰⢡⠋⣴⢩⡔⢩⠓⣾⢡⠊⢱⡌⢩⡌⢱⢰⠈⡍⣿⣷⣾⡏⡝⢠⠚⣤⢢⠑⣦⠑⢪⠐⢡⠊⣿⡔⣷⡞⢡⠚⣽⣿⣽⣾⣷⡟⢱⡌⢪⡅⣾⠀⠀
  ⠀⠀⠀⢷⣎⠒⡭⢰⡉⢎⠦⢩⠜⡤⢃⠶⠡⢞⣿⡾⠼⠶⠾⢶⢛⣶⣶⣧⢧⣱⣁⣆⡹⠷⣌⣒⣌⣢⣑⡠⢍⡂⠓⣌⣲⣽⢷⡰⢌⠣⢎⡰⢙⠯⡿⠿⡘⢢⡑⠪⢼⡏⠀⠀
  ⠀⠀⠀⠈⢯⡓⣌⠣⡜⢬⡘⣡⠚⠴⣉⠲⣉⠆⣼⠣⡜⣩⠱⢌⢦⡿⠘⠯⣝⣳⡿⣻⣖⣾⠟⠁⠀⠈⠹⣏⠹⣙⠫⢍⡩⣹⡆⡜⢢⡙⠦⡱⣉⠦⡡⢃⡍⠦⠱⣩⡿⠁⠀⠀
  ⠀⠀⠀⠀⠈⣷⠠⡓⡜⡰⡘⢤⡙⢦⢡⠓⡬⢸⡧⡱⡘⢤⠓⡌⣾⡇⠀⠀⠀⠉⠋⠉⠉⠀⠀⠀⠀⠀⠀⣧⠓⡬⡑⠎⡔⢡⣷⠌⡱⢌⢣⡑⢆⢣⡑⢣⠜⡌⢣⡿⠁⠀⠀⠀
  ⠀⠀⠀⠀⠀⠘⣧⠱⢌⡱⢉⠦⡘⢆⢣⡉⢆⣿⠰⣡⠙⢆⡹⠰⣽⠃⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⢹⠎⠴⣉⢚⡘⠤⣹⡎⠴⡉⢆⠭⡘⢢⡉⢆⠳⣌⣿⠃⠀⠀⠀⠀`);
  console.log('WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI WAGMI ')
  return (
<div className="App noselect">
<header className="App-header">
  <div className="eth-token-container">
        <img src={'./POPO-large.gif'} className="App-logo" alt="logo" height={250} width={250}/>
  </div>
  <div className="tagline-container">
    <h1>$POPO = <span className='gradient-text'>PROFIT</span></h1>
  </div>
  <div className="address-container courier-prime-regular">
    <p>We're the bad cop on the crypto block, playing by our own rules, but keeping it fair with no presale, no bribes and no fines - just pure community drive!</p>
  </div>
  <div className="address-container">
    <a href='https://www.pump.fun/HtDZKXMFqVqMNySf2b7ND6T2ynP3ysvqiSjGokbgAfqd' class="nes-btn is-success" target='_blank'>PUMP.FUN</a>
  </div>
  <div className="socials-container">
  <a href="https://twitter.com/PopoProfit" target='_blank' alt="$POPO Official Twitter!"><i className="nes-icon twitter is-large"></i></a>
  {/* <button type="button" class="nes-btn is-success">PUMP.FUN</button> */}
  </div>
</header>
</div>
  );
}

export default App;
